var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// Please use this instead of MUI Typography so we can have sensible defaults.
import MuiTypography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { forwardRef } from 'react';
var useStyles = makeStyles(function () { return ({
    // Margin here, including sibling adjustments.
    // This is so the native MUI components don't get messed up by our typo styles.
    // RichText Editor must use this component for typography!
    h1: {
        marginBottom: '2.4rem',
    },
    h2: {
        marginBottom: '2.4rem',
        'p+&': {
            marginTop: '4.8rem',
        },
    },
    h3: {
        marginBottom: '1.6rem',
    },
    h4: {
        marginBottom: '1.6rem',
    },
    h5: {
        marginBottom: '1.6rem',
    },
    h6: {
        marginBottom: '1.6rem',
    },
    subtitle1: {
        marginBottom: '1.6rem',
    },
    subtitle2: {
        marginBottom: '1.6rem',
    },
    body1: {
        marginBottom: '1.6rem',
    },
    body2: {
        marginBottom: '1.6rem',
    },
    overline: {
        marginBottom: '1.6rem',
    },
    noMargin: {
        marginBottom: '0',
    },
    // components
    li: {
        marginBottom: 0,
        '& > *': {
            margin: 0,
        },
    },
    td: {
        '& > *': {
            margin: 0,
        },
    },
    th: {
        '& > *': {
            margin: 0,
        },
    },
    // black: {
    //   fontWeight: 900,
    // },
    // extrabold: {
    //   fontWeight: 800,
    // },
    // bold: {
    //   fontWeight: 700,
    // },
    // semibold: {
    //   fontWeight: 600,
    // },
    // medium: {
    //   fontWeight: 500,
    // },
    // regular: {
    //   fontWeight: 400,
    // },
    // light: {
    //   fontWeight: 300,
    // },
    // extralight: {
    //   fontWeight: 200,
    // },
    // thin: {
    //   fontWeight: 100,
    // },
}); });
var Typography = forwardRef(function (props, ref) {
    var children = props.children, className = props.className, noMargin = props.noMargin, component = props.component, _a = props.variant, variant = _a === void 0 ? 'body1' : _a, rest = __rest(props, ["children", "className", "noMargin", "component", "variant"]);
    var classes = useStyles();
    // Used for margins
    function getVariantClass() {
        if (classes[variant])
            return classes[variant];
        return null;
    }
    // Used for nested elements
    function getComponentClass() {
        if (classes[variant])
            return null;
        if (typeof component === 'string' && classes[component])
            return classes[component];
        return null;
    }
    return (_jsx(MuiTypography, __assign({ ref: ref, component: component, variant: variant, className: classNames(getVariantClass(), getComponentClass(), className, noMargin ? classes.noMargin : null) }, rest, { children: children })));
});
export default Typography;
