var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useState } from 'react';
var useStyles = makeStyles(function () { return ({
    fallbackVideo: {
        width: '100%',
        height: '100%',
    },
    videoCover: {
        objectFit: 'cover',
    },
}); });
var VideoFallback = function (_a) {
    var className = _a.className, _b = _a.minHeight, minHeight = _b === void 0 ? 200 : _b;
    return (_jsxs("svg", __assign({ className: className, style: { minHeight: minHeight } }, { children: [_jsx("rect", { width: "100%", height: "100%", fill: "rgba(0,0,0,0.12)", stroke: "white" }), _jsx("line", { stroke: "white", strokeWidth: 0.5, x1: "100%", y1: "0", x2: "0", y2: "100%" })] })));
};
function Video(props) {
    var _a;
    var id = props.id, mediaRef = props.mediaRef, className = props.className, video = props.video, mimetype = props.mimetype, autoplay = props.autoplay, muted = props.muted, controlsList = props.controlsList, cover = props.cover, _b = props.controls, controls = _b === void 0 ? true : _b, loop = props.loop, onClick = props.onClick, handleTimeUpdate = props.handleTimeUpdate, handleLoadedMetadata = props.handleLoadedMetadata, handleLoadedData = props.handleLoadedData, style = props.style;
    var classes = useStyles(props);
    var _c = useState(false), error = _c[0], setError = _c[1];
    if (!video.path || error) {
        return _jsx(VideoFallback, { className: classNames(classes.fallbackVideo, className) });
    }
    var _d = video || {}, path = _d.path, poster = _d.poster;
    path += '?opt';
    return (_jsx("video", __assign({ id: id, ref: mediaRef, controls: controls, autoPlay: autoplay, muted: muted === undefined ? autoplay : muted, loop: loop, poster: poster ? poster : null, preload: controls && poster ? 'none' : 'auto', playsInline: true, controlsList: controlsList, onError: function () { return setError(true); }, className: classNames(className, (_a = {}, _a[classes.videoCover] = cover || (controls && poster), _a)), onClick: onClick, onTimeUpdate: handleTimeUpdate, onLoadedMetadata: handleLoadedMetadata, onLoadedData: handleLoadedData, style: style }, { children: _jsx("source", { src: path, type: mimetype }) }), path));
}
export default Video;
