import makeStyles from '@mui/styles/makeStyles';
import { filterClasses } from 'shared/utils/filterClasses';
var useStyles = makeStyles(function () { return ({
    contentModuleMedia: {
        display: 'block',
        width: '100%',
        margin: '3.2rem auto',
    },
    contentModuleMediaCaption: {
        display: 'block',
        margin: '-1.6rem auto 3.2rem',
        fontSize: '1.4rem',
        textAlign: 'center',
    },
    contentModuleMediaBoxCaption: {
        padding: '2.4rem',
        maxWidth: '50rem',
        textAlign: 'center',
        margin: '-6.3rem auto 2rem auto',
        position: 'relative',
        zIndex: 1,
    },
    contentModuleSliderWrapper: {
        display: 'block',
        width: '100%',
        margin: '3.2rem auto 0',
    },
    contentModuleSliderMedia: {
        maxWidth: '100%',
        flex: 1,
        aspectRatio: '4 / 3',
    },
    contentModuleContentSliderWrapper: {
        maxWidth: '40rem',
        margin: '0 auto',
    },
    contentModuleContentSliderItem: {},
    contentModuleContentSliderMedia: {
        width: '100%',
        marginBottom: '1.6rem',
    },
    contentModuleText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '1.6rem',
    },
    contentModuleButtonGroup: {
        padding: '0 1.6rem 4.4rem',
    },
    contentModuleQuizSliderButton: {},
    contentModuleButtonGroupCheckbox: {
        paddingTop: '2.4rem',
    },
    contentModuleBlockContentColumn: {
        '& > div > *:last-child': {
            marginBottom: '2.4rem',
        },
    },
    contentModuleBlockTitle: {},
    contentModuleInfoBlockTitle: {},
    contentModuleInfoBlockPoster: {
        margin: '2.4rem auto',
    },
    contentModulePrizeBlockTitle: {
        marginTop: '3.6rem',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    contentModulePrizeBlockSlideTitle: {},
    contentModuleRankingWrapper: {
        padding: '2.4rem 0',
    },
    contentModuleRankingTab: {
        marginTop: '2.4rem',
    },
    contentModuleRaffle: {
        paddingBottom: '2.4rem',
    },
    contentModuleBlogTitle: {
        paddingTop: '3.6rem',
        paddingBottom: '2.4rem',
        marginBottom: '-1px',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    contentModuleBlogPostsWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1.6rem',
        padding: '1.6rem 1.6rem 3.6rem 1.6rem',
        overflowX: 'scroll',
    },
    contentModuleBlogFilter: {
        padding: '0 1.6rem 0 1.6rem',
        marginBottom: '1.6rem',
    },
    contentModulePoll: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '0.5rem',
        paddingBottom: '1.6rem',
        maxWidth: '343px',
    },
    contentModuleQuizSlider: {
        maxWidth: 400,
        margin: '0 auto',
        overflow: 'hidden',
        position: 'relative',
        height: 620,
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    contentModuleQuizSliderButtonGroup: {
        width: '100%',
        boxSizing: 'border-box',
    },
    contentModuleQuizSliderItemsList: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 0,
        display: 'flex',
        alignItems: 'flex-end',
    },
    contentModuleQuizSliderItem: {
        height: '100%',
    },
    contentModuleQuizSliderItemLockIcon: {
        fontSize: '6rem',
    },
    contentModuleQuizSliderItemTitle: {},
    contentModuleQuizSliderItemSubtitle: {},
    contentModuleQuizSliderNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 1,
    },
    contentModuleQuizSliderNavButton: {
        width: 48,
        height: 48,
        background: 'none',
        border: 'none',
        '&:not(:disabled)': {
            cursor: 'pointer',
        },
    },
    contentModuleWrapper: {},
    ranking: {},
    rankingTitle: {},
    rankingTableHeader: {},
    rankingPlace: {},
    rankingUserRow: {},
}); });
export default function useContentModuleStyles(props) {
    var classList = [
        'contentModuleMedia',
        'contentModuleMediaCaption',
        'contentModuleMediaBoxCaption',
        'contentModuleSliderWrapper',
        'contentModuleSliderMedia',
        'contentModuleContentSliderItem',
        'contentModuleContentSliderMedia',
        'contentModuleText',
        'contentModuleButtonGroup',
        'contentModuleBlockTitle',
        'contentModuleInfoBlockPoster',
        'contentModuleBlockContentColumn',
        'contentModuleWrapper',
        'contentModuleInfoBlockTitle',
        'contentModulePrizeBlockTitle',
        'contentModulePrizeBlockSlideTitle',
        'contentModuleRankingWrapper',
        'contentModuleRankingTab',
        'contentModuleBlogPostsWrapper',
        'contentModuleBlogFilter',
        'contentModuleBlogTitle',
        'contentModuleRaffle',
        'contentModulePoll',
        'contentModuleQuizSlider',
        'contentModuleQuizSliderButtonGroup',
        'contentModuleQuizSliderButton',
        'contentModuleQuizSliderItemsList',
        'contentModuleQuizSliderItem',
        'contentModuleQuizSliderItemLockIcon',
        'contentModuleQuizSliderItemTitle',
        'contentModuleQuizSliderItemSubtitle',
        'contentModuleQuizSliderNav',
        'contentModuleQuizSliderNavButton',
        'ranking',
        'rankingTitle',
        'rankingTableHeader',
        'rankingPlace',
        'rankingUserRow',
    ];
    var classes = useStyles({ classes: filterClasses(props.classes, classList) });
    return classes;
}
