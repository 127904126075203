var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClickAwayListener, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import debounce from 'lodash/debounce';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import { TOP_BANNER_ID } from '../../Page';
import { MenuItems } from './MenuItems';
import PageExtendedMenu from './PageExtendedMenu';
export var MENU_WIDTH_DESKTOP = 440;
export var MENU_HEIGHT = 64;
export var EXTENDED_MENU_ID = 'ExtendedMenu';
export var SECTION_BOTTOM_ID = 'sectionBottom';
var useStyles = makeStyles(function (_a) {
    var _b, _c;
    var _d;
    var components = _a.components, palette = _a.palette, breakpoints = _a.breakpoints;
    return deepmerge({
        menuWrapper: (_b = {
                height: MENU_HEIGHT,
                width: '100%'
            },
            _b[breakpoints.up('sm')] = {
                height: function (_a) {
                    var hwStatic = _a.hwStatic;
                    return (hwStatic ? 80 : 'auto');
                },
            },
            _b),
        menu: (_c = {
                display: 'flex',
                backgroundColor: palette.background.default,
                color: palette.text.primary,
                position: 'relative',
                height: MENU_HEIGHT,
                borderTop: "1px solid currentColor",
                zIndex: 2
            },
            _c[breakpoints.up('sm')] = {
                position: 'absolute',
                bottom: function (_a) {
                    var hwStatic = _a.hwStatic;
                    return !hwStatic && 'calc(10vh + 30px)';
                },
                left: '50%',
                zIndex: 2,
                transform: "translateX(-".concat(MENU_WIDTH_DESKTOP / 2, "px)"),
                border: 0,
                borderRadius: 15,
                boxShadow: '0px 0px 5px rgba(0,0,0,.5)',
                boxSizing: 'border-box',
                width: MENU_WIDTH_DESKTOP,
                padding: '0 1rem',
            },
            _c),
        link: {
            color: palette.text.primary,
            textDecoration: 'none',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%',
            padding: '0 .7rem',
            boxSizing: 'border-box',
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 5,
                right: 20,
                width: 10,
                height: 10,
                backgroundColor: palette.storyAdsBadge.main,
                borderRadius: '50%',
                display: 'none', // By default, the dot is not shown
            },
        },
        linkWithRedDot: {
            '&:after': {
                display: 'block',
            },
        },
        current: {},
        name: {
            display: 'block',
            maxWidth: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 500,
        },
        icon: {
            position: 'relative',
            fontSize: '2.4rem',
        },
        iconExtended: {
            position: 'absolute',
            left: '-1px',
            top: 0,
            opacity: 0,
            transition: 'opacity .2s',
            height: '100%',
            width: 'calc(100% + 2px)',
            backgroundColor: palette.background.default,
            textAlign: 'center',
        },
        iconExtendedOpen: {
            opacity: 1,
        },
        iconPlaying: {
            animation: 'pulse .5s infinite',
        },
    }, (_d = components.CxmPageMainMenu) === null || _d === void 0 ? void 0 : _d.styleOverrides);
});
function PageMainMenu(props) {
    var _a = props.main, main = _a === void 0 ? [] : _a, _b = props.extended, extended = _b === void 0 ? [] : _b, iconsLight = props.iconsLight;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var mainMenu = useRef(null);
    var _d = useAppState(), user = _d.user, isHealthWarningStatic = _d.isHealthWarningStatic;
    var classes = useStyles({ hasHealthWarning: props.hasHealthWarning, hwStatic: isHealthWarningStatic });
    var hasStoryAds = extended.some(function (item) { return item.id === 'story-ads'; });
    var closeMenu = function () {
        setOpen(false);
    };
    var toggleMenu = function () {
        setOpen(!open);
    };
    var resizeExtendedMenu = function (menuOpen) {
        var extendedMenuEl = document.getElementById(EXTENDED_MENU_ID);
        var bottomBar = document.getElementById(SECTION_BOTTOM_ID);
        var topBanner = document.getElementById(TOP_BANNER_ID);
        if (!extendedMenuEl || !bottomBar || !topBanner)
            return;
        // console.log('resizeExtendedMenu')
        var bottomBarHeight = bottomBar.clientHeight;
        var topBannerHeight = topBanner.clientHeight;
        if (menuOpen) {
            var marginTop = void 0, top_1, height = void 0;
            if (window.innerWidth >= 600) {
                marginTop = bottomBarHeight + topBannerHeight + 16; // 16 is 2 * 8 spacing
                top_1 = window.innerHeight * -1 + marginTop;
                // 75 is the height of the menu, 8 is the spacing
                height = window.innerHeight - marginTop - 8;
                if (!isHealthWarningStatic)
                    height -= 75;
            }
            else {
                marginTop = bottomBarHeight + topBannerHeight;
                top_1 = window.innerHeight * -1 + marginTop;
                height = window.innerHeight - marginTop;
            }
            extendedMenuEl.style.top = "".concat(top_1, "px");
            extendedMenuEl.style.height = "".concat(height, "px");
            extendedMenuEl.dataset.visible = 'true';
        }
        else {
            extendedMenuEl.style.top = '100px'; // default value, see PageExtendedMenu.tsx line 16
            extendedMenuEl.style.height = '0px';
            extendedMenuEl.dataset.visible = 'false';
        }
    };
    useEffect(function () {
        resizeExtendedMenu(open);
    }, [open]);
    useLayoutEffect(function () {
        var lastHeight = window.innerHeight;
        var lastWidth = window.innerWidth;
        var debouncedResizeMenu = debounce(function () { return resizeExtendedMenu(true); }, 300);
        var updateMenu = function () {
            var _a;
            var isMenuOpen = ((_a = document.getElementById(EXTENDED_MENU_ID)) === null || _a === void 0 ? void 0 : _a.getAttribute('data-visible')) === 'true';
            var newWidth = window.innerWidth;
            var newHeight = window.innerHeight;
            if (newWidth !== lastWidth)
                closeMenu();
            if (isMenuOpen && newHeight !== lastHeight)
                debouncedResizeMenu();
            lastWidth = newWidth;
            lastHeight = newHeight;
        };
        window.addEventListener('resize', updateMenu);
        return function () { return window.removeEventListener('resize', updateMenu); };
    }, []);
    if (!user || !main.length)
        return null;
    return (_jsx(ClickAwayListener, __assign({ onClickAway: closeMenu }, { children: _jsxs("aside", __assign({ id: 'MenuWrapper', className: classes.menuWrapper }, { children: [extended.length !== 0 && (_jsx(PageExtendedMenu, { id: EXTENDED_MENU_ID, items: extended, onClose: closeMenu, MenuItems: MenuItems, iconsLight: iconsLight })), _jsx(Typography, __assign({ id: 'MainMenu', ref: mainMenu, component: "nav", variant: "subtitle1", className: classes.menu, noMargin: true }, { children: _jsx(MenuItems
                    // make sure we just have 5 items
                    , { 
                        // make sure we just have 5 items
                        items: main.filter(function (o, i) { return i < 5; }), classes: classes, toggleMenu: toggleMenu, closeMenu: closeMenu, open: open, iconsLight: iconsLight, hasStoryAds: hasStoryAds }) }))] })) })));
}
export default function ThemedPageMainMenu(props) {
    // get theme from default options
    var mainMenuTheme = useTheme().mainMenuTheme;
    // component without theme - will inherit the theme
    if (!mainMenuTheme)
        return _jsx(PageMainMenu, __assign({}, props));
    // themed component
    var theme = CXMTheme.get(mainMenuTheme);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(PageMainMenu, __assign({}, props)) })));
}
