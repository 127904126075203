"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOYALTY_LIMITATIONS = exports.LOYALTY_TRIGGER = exports.LOY_ORDER_STATUS = exports.LOY_LOGISTIC_PARTNER = exports.LOY_BUNDLE_TYPE = void 0;
exports.LOY_BUNDLE_TYPE = {
    GAME: 'game',
    GIVEAWAY: 'giveaway',
    PACK_SAMPLE: 'pack-sample',
    RAFFLE: 'raffle',
    REWARD: 'reward',
    WELCOME: 'welcome',
    VARIANT: 'variant',
};
exports.LOY_LOGISTIC_PARTNER = {
    DIGITAL: 'digital',
    NONE: 'none',
    BWO: 'bwo',
    MARGOT: 'margot',
    DAVIESMEYER: 'daviesmeyer',
    KNSK: 'knsk',
    ONLINE_TABAK_SHOP: 'online-tabak-shop',
    TABAK_BOERSE24: 'tabak-boerse24',
    TABAK_BRUCKER: 'tabak-brucker',
    TABAKGURU: 'tabakguru',
    TABAKHERO: 'tabakhero',
    TABAKLAND: 'tabakland',
    TABAKSTORE: 'tabakstore',
    TABAK_WELT: 'tabak-welt',
    ZEDACO: 'zedaco',
    ZENGER: 'zenger',
};
exports.LOY_ORDER_STATUS = {
    SUBMITTED: 'submitted',
    PROCESSING: 'processing',
    DISPATCHED: 'dispatched',
    CANCELLED: 'cancelled',
    RETURNED: 'returned',
    FAILED: 'failed',
    ON_HOLD: 'onhold',
    DELIVERED: 'delivered',
    NEW: 'new',
    TEST: 'test',
};
exports.LOYALTY_TRIGGER = {
    ADVENT_CALENDAR: 'advent-calendar',
    ARTICLE_READING: 'article-reading',
    ARTICLE_LIKE: 'article-like',
    COMEBACK_SIX_MONTHS: 'comeback-six-months',
    COMEBACK_THREE_MONTHS: 'comeback-three-months',
    DONATION: 'donation',
    EXPIRATION: 'expiration',
    EXTENSION_REWARD: 'extension-reward',
    EXTENSION_USAGE: 'extension-usage',
    GAMBLE: 'gamble',
    GAMBLE_FLAT_POINTS: 'gamble-flat-points',
    GAME_ITEM: 'game-item',
    GAME_REWARD: 'game-reward',
    INSTANT_PRIZE: 'instant-prize',
    INVITE_MEMBER: 'invite-member',
    INVITE_ACCEPTED: 'invite-accepted',
    JAM: 'jam',
    JURY_REWARD: 'jury-reward',
    LOGIN: 'login',
    MEMBER_BIRTHDAY: 'member-birthday',
    RADIO_COVER: 'radio-cover',
    REGISTRATION: 'registration',
    REGISTRATION_ANNIVERSARY: 'registration-anniversary',
    NEWSLETTER_CTA: 'newsletter-cta',
    NEWSLETTER_SUBSCRIPTION: 'newsletter-subscription',
    REWARD_ORDER: 'reward-order',
    SCAN_PACK_CODE: 'scan-pack-code',
    SURVEY_PARTICIPATION: 'survey-participation',
    POLL_ANSWER: 'poll-answer',
    PERSONALITY_TEST_PARTICIPATION: 'personality-test-participation',
    QUIZ_ANSWER: 'quiz-answer',
    TELEGRAM_ENTRY: 'telegram-entry',
    STORY_AD: 'story-ad',
    AB_QUIZ: 'ab-quiz',
    PROMOTION_PARTAKE: 'promotion-partake',
    PACK_SAMPLING_POINTS: 'pack-sampling-points',
    ADDRESS_ADDITION: 'address-addition',
    BIRTHDAY_ADDITION: 'birthday-addition',
    GENDER_ADDITION: 'gender-addition',
    NEW_PROFILE_INTEREST: 'new-profile-interest',
    BONUS_POINTS: 'bonus-points',
    CONSUMER_SERVICE: 'consumer-service',
    FESTIVAL_VISIT: 'festival-visit',
};
exports.LOYALTY_LIMITATIONS = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    UNLIMITED: 'unlimited',
};
