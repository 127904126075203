var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import IconButton from 'shared/components/IconButton';
import { usePopup } from 'shared/core/PopupWrapper';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        root: {
            position: 'relative',
            zIndex: 2,
        },
        closeButton: {
            position: 'absolute',
            zIndex: 1,
            right: 0,
            top: 0,
        },
    }, (_b = components.CxmPopupHeader) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function PopupHeader(props) {
    var children = props.children, onCloseProp = props.onClose, className = props.className, _a = props.iconButtonPrimary, iconButtonPrimary = _a === void 0 ? true : _a, hideClose = props.hideClose;
    var classes = useStyles();
    var onCloseContext = usePopup().onClose;
    var onClose = onCloseProp || onCloseContext;
    return (_jsxs("div", __assign({ className: classNames(classes.root, className) }, { children: [children, onClose && !hideClose && (_jsx(IconButton, { icon: "times", "aria-label": "close", className: classes.closeButton, primary: iconButtonPrimary, onClick: onClose, size: "large" }))] })));
}
export default PopupHeader;
